/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(14, 12, 133);
    border-radius: 5px;
}