/*  landing intro styling */
.landing-intro {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 70vh; */
    /* animation */
    opacity: 0;
    transform: translateX(50px);
    animation: fadeIn2 2s forwards;
    border-bottom-left-radius: 70px;
    box-shadow: 0px 2px 4px 0px  rgba(121, 119, 219, 0.529);
    background-image: linear-gradient(188deg, rgba(119, 138, 248, 0.867),
                                    rgba(54, 124, 215, 0.545),rgba(13, 11, 107, 0.85),
                                    rgba(8, 7, 80));
    .text-container {
        /* width: 40%; */
        h1 {
            text-align: right;
            color: white;
            text-shadow: 5px 5px 15px rgba(111, 110, 110, 0.337);
            /* animation */
            opacity: 0;
            transform: translateX(-100px);
            animation: fadeIn 2s forwards;
        }
        p {
            /* text-align: left; */
            /* font-size: 1.5vh; */
            font-weight: 400;
            color: rgb(255, 255, 255);
            text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.429);
            /* animation */
            opacity: 0;
            transform: translateX(100px);
            animation: fadeIn 2s forwards;
        }
    }
}



/* Smaller devices (phones, less than 600px) */
@media (max-width: 600px) {
    .landing-intro {
        height: 63vh;
    }
    .text-container {
        width: 70%;
        h1 {
            font-size: 12vw;
        }
        p {
            text-align: center;
            font-size: 1.2vh;
        }
    }
}


/* Medium devices (tablets, 600px to 900px) */
@media (min-width: 600px) and (max-width: 1050px) {
    .landing-intro {
        height: 70vh;
    }
    .text-container {
        width: 70%;
        h1 {
            font-size:  11vw;
        }
        p {
            text-align: center;
            
            font-size: 1.3vh;
        }
    }
}

/* Large devices (desktops, 900px and up) */
@media (min-width: 1050px) {
    .landing-intro {
        height: 70vh;
    }
    .text-container {
        width: 35%;
        h1 {
            font-size: 6vw;
        }
        p {
            text-align: center;
            font-size: 1.6vh;
        }
    }
}