.FAQs {
    /* max height setting? */
    /* background-color: rgba(13, 11, 107, 0.90); */
    background-image: linear-gradient(-188deg,
                                    rgba(13, 11, 107, 0.85),
                                    rgba(8, 7, 80));
    box-shadow: 0px 2px 4px 0px rgba(159, 159, 159, 0.5);  
    border-top-right-radius: 30px;
    border-bottom-left-radius: 20px;
    padding: 10px;
    padding-bottom: 20px;
    margin: 5px;
    margin-top: 80px;
    margin-bottom: 80px;
    .FAQ-title {
        text-align: center;
        color: white;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);    
        border-top-right-radius: 25px;
        border-bottom-left-radius: 10px;
        padding: 10px;
    }
    .FAQ-questions-container {
        height: 60vh;
        overflow-y: scroll;
        
    }
    .FAQ-questions-container::-webkit-scrollbar-thumb{
        background-color: rgba(255, 255, 255);
    }
    .FAQ-question {
        color:white;
        padding: 10px;
        margin: 0px;
        transition: 0.7s;
    }
    .FAQ-question:hover {
        background-color: rgba(22, 22, 22, 0.27);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        transition: 0.7s;
    }
    .FAQ-answer {
        color: rgba(8, 7, 80);
        background-color:  white;
        padding: 10px;
        margin: 0px;
        border-radius: 10px;
        transition: 0.7s;
        font-size: small;
        ul {
            list-style-type: none;
        }
        strong {
            font-weight:600;
        }
        a {
            color: rgb(87, 153, 252);
            text-decoration: none;
            transition: 0.7s;
            font-weight: 600;
        }
        a:hover {
            color: rgb(0, 55, 255);
            transition: 0.7s;
        }
    }
}