.content-container {
    margin:auto;
    position: relative;
}

/* STARTER PACK */
.StarterPack {
    margin-top: 65px;
    margin-bottom: 80px;
    text-align: center;
    h2 {
        color:  rgb(31, 29, 157);
        /* animation */
        opacity: 0;
        transform: translateY(100px);
        animation: fadeIn 2s forwards;
    }
    p {
        font-size: small;
        color:  rgb(31, 29, 157);
        /* animation */
        opacity: 0;
        transform: translateY(100px);
        animation: fadeIn 2s forwards;
    }
    .pack-link-container {
        margin: auto;
    }
    a {
        text-decoration: none;
        button {
            color: rgb(31, 29, 157);
            margin: 5px;
            height: 5vh;
            width: 21vh;
            font-size: 1.5vh;
            border-radius: 100px;
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(244, 244, 244);
            box-shadow: 0px 2px 4px 0px rgba(178, 178, 178, 0.787);
            /* transition */
            transition: 0.6s;
            /* animation */
            opacity: 0;
            transform: translateY(100px);
            animation: fadeIn 2s forwards;
        }
        button:hover {
            box-shadow: 0px 2px 4px 0px  rgba(69, 67, 208, 0.756);
            transition: 0.6s;
        }
    }
}


/* WHAT DO YOU ACTUALLY DO? */
#WhatYouDo {
    /* align internal columns centre */
    display: flex;
    justify-content: center;
    .col-sm-3 {
        display: block;
        text-align: center;
        h3 {
            color: rgb(14, 12, 133);
        }
    }
    .col-sm-4 {
        display: block;
        height: 100%;
        div {
            border-radius: 10px;
            box-shadow: 0px 2px 4px 0px rgba(178, 178, 178, 0.65);
            transition:0.6s;
            margin: 5px;
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 20px;
            h6 {
                color:rgb(14, 12, 133);
            }
            p {
                font-size: 1.5vh;
                color:rgb(33, 31, 151);
            }
        }
        div:hover {
            box-shadow: 0px 2px 5px 0px  rgb(120, 120, 120);
            transition: 0.5s;
        }
    }
}

#up-arrow {
    img {
        height: 5vh;
        background-color: rgb(14, 12, 133);
        transition: 0.7s;
        border-radius: 100px;
        position: absolute;
        bottom: -100px; /* may change this after more content*/
        right: 49%;
    }
    img:hover {
        background-color: rgb(175, 174, 243);
        transition: 0.7s;
        transform: translateY(-10px);
    }
}


/* WHY I MADE THIS */
.Why {
    text-align: center;
    width: 100%;
    font-size: small;
    margin-top: 100px;
    margin-bottom: 150px;
    color: rgb(14, 12, 133);
}


/* Animation set-up */


/* Small-Medium devices (tablets, 600px to 900px) */
@media (max-width: 1050px) {
    .content-container {
        width: 90%;
    }
}

/* Large devices (desktops, 900px and up) */
@media (min-width: 1050px) {
    #WhatYouDo {
        margin: auto;
    }
    .content-container {
        width: 85%;
        .pack-link-container {
            width: 80%;
        }
    }
    /* .col-sm-3 {
        margin-right: 80px;
    } */
}