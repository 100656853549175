#main-nav {
    height: 10vh;
    /* margin-bottom: 10px; */
    box-shadow: 0px 2px 5px 0px rgb(219, 219, 219);
    .col-sm-4 {
        /* display: flex; */
        justify-content: center;
        align-items: center;
        a {
            text-decoration: none;
            color: rgb(185, 185, 185);
            padding-left: 5px;
            padding-right: 5px;
            button {
                max-height: 100%;
            }
            img {
                height: 2vh;
            }
        }
    }
}

@media (min-width: 500px) {
    .col-sm-4 {
        display: flex;
    }
}


/* key frames */

/* mobile styling */
@media (max-width: 500px) {
    #nav-left, #nav-right {
        display: flex;
    }
    #nav-center {
        display: none;
    }
}