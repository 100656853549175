/* animation */
@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn2 {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}